$(document).ready(function () { 
    let $pageUrl = $(location).attr('pathname').match('/news/(.*)\.html');
    if ($pageUrl) {
        let $imgUrlPath = 'http://flgroup:8080/storage/';
        var response = $.ajax({
            url:      'http://flgroup:8080/get_news?url=' + $pageUrl[1],
            method:   'GET',
            dataType: 'jsonp',
            jsonpCallback: "jsonCallback",
            success: function ($news){
                let $news_section = $('div[id^="news_section"]');
                $news_section.find('.title').text($news.title);
                $news_section.find('.body').text($news.body);
                if ($news.image) {
                    $news_section.find('.image').attr('src', $imgUrlPath + $news.image.image).attr('alt', $news.image_alt);
                } else {
                    $news_section.find('.image').remove();
                }
                $("link[rel='canonical']").attr('href', 'https://1l.lt/' + $pageUrl[1] + '.html');
            },
            error: function (xhr, status) {
                console.log(xhr);
                console.log(status);
            }
        });
    } else if ($(location).attr('pathname') == "/news.html") {
        let $imgUrlPath = 'http://flgroup:8080/storage/';
        var response = $.ajax({
            url:      'http://flgroup:8080/get_news',
            method:   'GET',
            dataType: 'jsonp',
            jsonpCallback: "jsonCallback",
            success: function ($news){
                $.each($news, function($index, $value ) {
                    let $news_section = $('div[id^="news_section"]:last');
                    let num = parseInt($news_section.prop("id").match(/\d+/g), 10)+1;
                    let $news_section_new = $news_section.clone().prop('id', 'news_section' + num);
                    $news_section_new.find('.title').text($news[$index].title);
                    $news_section_new.find('.body').text($news[$index].body);
                    $news_section_new.find('a').attr('href', 'news/' + $news[$index].url + '.html');
                    if ($news[$index].image) {
                        $news_section_new.find('.image').attr('src', $imgUrlPath + $news[$index].image.image).attr('alt', $news[$index].image_alt);
                    } else {
                        $news_section_new.find('.image').remove();
                    }
                    $news_section.after($news_section_new);
                });
                $('div#news_section0').remove();
            },
            error: function (xhr, status) {
                console.log(xhr);
                console.log(status);
            }
        });
    }
});
